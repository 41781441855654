import React from 'react'
import {Button} from 'reactstrap';
import {ColorBubble} from '../helpers/tags'

const ClientTable = props => (
    <table>
        <thead>
        <tr>
            <th colSpan={2}>Kunde</th>
            <th>Standort</th>
            <th>Schulden</th>
            <th colSpan={2}></th>
        </tr>
        </thead>
        <tbody>
        {props.clients.length > 0 ? (
            props.clients.map(client => (
                <tr key={client.id}>
                    <td><ColorBubble color={client.color}/></td>
                    <td>{client.name}</td>
                    <td>{client.location}</td>
                    <td>{client.debts ? 'Ja' : ''}</td>
                    <td><Button color="primary" onClick={() => props.editRow(client)}>Bearbeiten</Button></td>
                </tr>
            ))
        ) : (
            <tr>
                <td colSpan={4}>Keine Kunden</td>
            </tr>
        )}
        </tbody>
    </table>
)

export default ClientTable
