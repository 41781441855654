import React, {useEffect, useState} from 'react'
import {Button, ButtonGroup, CustomInput, FormGroup, Input, Label} from 'reactstrap';

const EditFriendForm = props => {
    const [currentFormState, setCurrentFormState] = useState(props.currentRow)

    useEffect(
        () => {
            setCurrentFormState(props.currentRow)
        },
        [props]
    )

    const handleInputChange = event => {
        const {name, value} = event.target

        setCurrentFormState({...currentFormState, [name]: value})
    }

    const handleSwitchChange = (name, value) => () => {
        setCurrentFormState({...currentFormState, [name]: !value})
    }

    return (
        <form
            onSubmit={event => {
                event.preventDefault()

                props.updateRow(currentFormState.id, currentFormState)
            }}
        >
            <FormGroup>
                <Label htmlFor="name">Name</Label>
                <Input type="text"
                       name="name"
                       id="name"
                       value={currentFormState.name}
                       onChange={handleInputChange}
                       required autoComplete="off"/>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="tel">Telefon</Label>
                <Input type="tel"
                       name="tel"
                       id="tel"
                       value={currentFormState.tel}
                       onChange={handleInputChange}
                       autoComplete="off"/>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="mail">E-Mail</Label>
                <Input type="mail"
                       name="mail"
                       id="mail"
                       value={currentFormState.mail}
                       onChange={handleInputChange}
                       autoComplete="off"/>
            </FormGroup>
            <FormGroup>
                <CustomInput type="switch"
                             name="lead"
                             id="lead"
                             label="Leader"
                             checked={currentFormState.lead}
                             onChange={handleSwitchChange('lead', currentFormState.lead)}
                             autoComplete="off"/>
            </FormGroup>
            <ButtonGroup>
                <Button color="primary">Speichern</Button>
                <Button color="secondary" onClick={() => props.setEditing(false)}>Abbrechen</Button>
            </ButtonGroup>
        </form>
    )
}

export default EditFriendForm
