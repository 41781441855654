import React from 'react'
import {Button} from 'reactstrap';
import {Alink} from '../helpers/tags'

const FriendTable = props => (
    <table>
        <thead>
        <tr>
            <th>Name</th>
            <th>Telefon</th>
            <th>E-Mail</th>
            <th colSpan={2}></th>
        </tr>
        </thead>
        <tbody>
        {props.friends.length > 0 ? (
            props.friends.map(friend => (
                <tr key={friend.id}>
                    <td>{friend.name}</td>
                    <td><Alink prot="tel" uri={friend.tel}/></td>
                    <td><Alink prot="mail" uri={friend.mail}/></td>
                    <td><Button color="primary" onClick={() => props.editRow(friend)}>Bearbeiten</Button></td>
                </tr>
            ))
        ) : (
            <tr>
                <td colSpan={4}>Wo isch de Ali?</td>
            </tr>
        )}
        </tbody>
    </table>
)

export default FriendTable
