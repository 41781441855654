import React, {useState} from 'react'
import {Button, CustomInput, FormGroup, Input, Label} from 'reactstrap';

import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/dark.css";
import {German} from "flatpickr/dist/l10n/de.js"

import moment from 'moment';

moment().locale("de");

const AddEventForm = props => {
    const initialFormState = {
        "id": "",
        "fkClient": -1,
        "fkItem": -1,
        "fkFriend": -1,
        "startDate": "",
        "endDate": "",
        "fix": 0,
        "molton": 0,
        "anbau": 0,
        "text": ""
    };
    const [currentFormState, setCurrentFormState] = useState(initialFormState)

    const handleInputChange = event => {
        const {name, value} = event.target

        setCurrentFormState({...currentFormState, [name]: value})
    }

    const handleCustomInputChange = event => {
        const {name, value} = event.target

        setCurrentFormState({...currentFormState, [name]: Number(value)})
    }

    const handleSwitchChange = (name, value) => () => {
        setCurrentFormState({...currentFormState, [name]: !value})
    }

    const handleDateChange = (dates) => () => {
        setCurrentFormState({...currentFormState, ...dates})
    }

    return (
        <form
            onSubmit={event => {
                event.preventDefault()
                if (!currentFormState.fkClient || !currentFormState.fkItem || !currentFormState.fkFriend) {
                    return;
                }

                props.addRow(currentFormState)
                setCurrentFormState(initialFormState)
            }}
        >
            <FormGroup>
                <Label htmlFor="fkClient">Kunde</Label>
                <CustomInput type="select"
                             name="fkClient"
                             id="fkClient"
                             onChange={handleCustomInputChange}
                             value={currentFormState.fkClient}
                             required>
                    <option key="-1"
                            value="-1"
                            disabled/>
                    {props.clients.map(option =>
                        <option key={option.id} value={option.id} disabled={option.debts ? true : null}>{option.name}</option>
                    )}
                </CustomInput>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="fkItem">Bühne</Label>
                <CustomInput type="select"
                             name="fkItem"
                             id="fkItem"
                             onChange={handleCustomInputChange}
                             value={currentFormState.fkItem}
                             required>
                    <option key="-1"
                            value="-1"
                            disabled/>
                    {props.items.map(option =>
                        <option key={option.id} value={option.id}>{option.name}</option>
                    )}
                </CustomInput>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="fkFriend">Zuständig</Label>
                <CustomInput type="select"
                             name="fkFriend"
                             id="fkFriend"
                             onChange={handleCustomInputChange}
                             value={currentFormState.fkFriend}
                             required>
                    <option key="-1" value="-1" disabled/>
                    {props.friends.filter(friend => friend.lead).map(option =>
                        <option key={option.id} value={option.id}>{option.name}</option>
                    )}
                </CustomInput>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="rangeDate">Datum</Label>
                <Flatpickr id="rangeDate"
                           value={currentFormState.startDate}
                           options={{
                               altFormat: "j F Y",
                               altInput: true,
                               dateFormat: "Y-m-d",
                               disable: props.orders.filter(order => order.fkItem === currentFormState.fkItem).map(order => {
                                   return {
                                       from: order.startDate,
                                       to: order.endDate
                                   }
                               }),
                               inline: true,
                               locale: German,
                               mode: "range",
                               showMonths: 3,
                           }}
                           onChange={range => {

                               const start = range[0];
                               const startDate = moment(start).format("YYYY-MM-DD");

                               const end = range.length === 2 ? range[1] : null;
                               const endDate = end ? moment(end).format("YYYY-MM-DD") : '';

                               setCurrentFormState({
                                   ...currentFormState,
                                   startDate: startDate
                               })

                               setCurrentFormState({
                                   ...currentFormState,
                                   endDate: endDate
                               })
                           }}/>
            </FormGroup>
            <FormGroup>
                <CustomInput type="switch"
                             name="fix"
                             id="fix"
                             label="Fix"
                             checked={currentFormState.fix}
                             onChange={handleSwitchChange('fix', currentFormState.fix)}
                             autoComplete="off"/>
            </FormGroup>
            <FormGroup>
                <CustomInput type="switch"
                             name="molton"
                             id="molton"
                             label="Molton"
                             checked={currentFormState.molton}
                             onChange={handleSwitchChange('molton', currentFormState.molton)}
                             autoComplete="off"/>
            </FormGroup>
            <FormGroup>
                <CustomInput type="switch"
                             name="anbau"
                             id="anbau"
                             label="Anbau"
                             checked={currentFormState.anbau}
                             onChange={handleSwitchChange('anbau', currentFormState.anbau)}
                             autoComplete="off"/>
            </FormGroup>
            <FormGroup>
                <Input type="textarea"
                       name="text"
                       id="text"
                       value={currentFormState.text}
                       onChange={handleInputChange}
                       autoComplete="off"/>
            </FormGroup>
            <FormGroup>
                <Button color="primary">Hinzufügen</Button>
            </FormGroup>
        </form>
    )
}

export default AddEventForm
