import React from 'react'
import {Button} from 'reactstrap';
import {ColorBubble, DateRange} from '../helpers/tags'

const OrderTable = props => (
    <table>
        <thead>
        <tr>
            <th colSpan={2}>Kunde</th>
            <th>Bühne</th>
            <th>Zuständig</th>
            <th>Datum</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        {props.orders.length > 0 ? (
            props.orders.map(order => (
                <tr key={order.id}>
                    <td><ColorBubble color={props.clients.find(client => order.fkClient === client.id).color}/></td>
                    <td>{props.clients.find(client => client.id === order.fkClient).name}</td>
                    <td>{props.items.find(item => item.id === order.fkItem).name}</td>
                    <td>{props.friends.find(friend => friend.id === order.fkFriend).name}</td>
                    <td><DateRange startDate={order.startDate} endDate={order.endDate}/></td>
                    <td><Button color="primary" onClick={() => props.editRow(order)}>Bearbeiten</Button></td>
                </tr>
            ))
        ) : (
            <tr>
                <td colSpan={6}>Keine Bestellungen</td>
            </tr>
        )}
        </tbody>
    </table>
)

export default OrderTable
