import React, {useEffect, useState} from 'react'
import {LoadingFailedToast, LoadingToast} from './helpers/tags'

import AddFriendForm from './forms/AddFriendForm'
import EditFriendForm from './forms/EditFriendForm'
import FriendTable from './tables/FriendTable'

const Friends = () => {
    const initialFormState = {
        "id": "",
        "name": "",
        "tel": "",
        "mail": "",
        "lead": 0
    }

    // Setting state
    const [isLoading, setLoading] = useState(true)
    const [hasError, setErrors] = useState(false)
    const [friends, setFriends] = useState(null)
    const [currentRow, setCurrentRow] = useState(initialFormState)
    const [editing, setEditing] = useState(false)

    async function fetchData() {
        const resFriends = await fetch("/api/friends.json");
        resFriends.json()
            .then(resFriends => setFriends(resFriends.sort((a, b) => a.name > b.name)))
            .catch(err => setErrors(err));
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setLoading(!friends)
    }, [friends]);

    if (hasError) {
        return <LoadingFailedToast/>
    }

    if (isLoading) {
        return <LoadingToast/>
    }

    // CRUD operations
    const addRow = async insertedRow => {
        insertedRow = await fetch("/api/friends.json", {
            method: 'POST',
            body: JSON.stringify(insertedRow),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => response.json());

        setFriends([...friends, insertedRow])
    }

    const updateRow = async (id, updatedRow) => {
        setEditing(false)

        updatedRow = await fetch(`/api/friends/${id}.json`, {
            method: 'PUT',
            body: JSON.stringify(updatedRow),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => response.json());

        setFriends(friends.map(friend => (friend.id === id ? updatedRow : friend)))
    }

    const editRow = async friend => {
        await fetchData();

        setEditing(true)

        setCurrentRow(friend)
    }

    return (
        <>
            <h1>Kultopia Kontakte</h1>
            <div className="flex-row">
                <div className="flex-large">
                    {editing ? (
                        <>
                            <h2>Bearbeiten</h2>
                            <EditFriendForm
                                editing={editing}
                                setEditing={setEditing}
                                currentRow={currentRow}
                                updateRow={updateRow}
                            />
                        </>
                    ) : (
                        <>
                            <h2>Erstellen</h2>
                            <AddFriendForm addRow={addRow}/>
                        </>
                    )}
                </div>
                <div className="flex-large">
                    <h2>Liste</h2>
                    <FriendTable friends={friends}
                                 editRow={editRow}
                    />
                </div>
            </div>
        </>
    )
}

export default Friends
