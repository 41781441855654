import React, {useState} from 'react'
import {Button, CustomInput, FormGroup, Input, InputGroup, InputGroupAddon, Label} from 'reactstrap';
import {RandomColor} from '../helpers/arrows'

const AddClientForm = props => {
    const initialFormState = {
        "id": "",
        "name": "",
        "color": RandomColor,
        "location": "",
        "debts": 0
    }
    const [currentFormState, setCurrentFormState] = useState(initialFormState)

    const handleInputChange = event => {
        const {name, value} = event.target

        setCurrentFormState({...currentFormState, [name]: value})
    }

    const handleSwitchChange = (name, value) => () => {
        setCurrentFormState({...currentFormState, [name]: !value})
    }

    return (
        <form
            onSubmit={event => {
                event.preventDefault()
                if (!currentFormState.name) return

                props.addRow(currentFormState)
                setCurrentFormState(initialFormState)
            }}
        >
            <FormGroup>
                <Label htmlFor="name">Name</Label>
                <InputGroup>
                    <Input type="text"
                           name="name"
                           id="name"
                           value={currentFormState.name}
                           onChange={handleInputChange}
                           autoComplete="off"
                           required/>
                    <InputGroupAddon addonType="append">
                        <Input type="color"
                               name="color"
                               id="color"
                               value={currentFormState.color}
                               onChange={handleInputChange}
                               autoComplete="off"
                               style={{width: "50px"}}/>
                    </InputGroupAddon>
                </InputGroup>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="location">Standort</Label>
                <Input type="text"
                       name="location"
                       id="location"
                       value={currentFormState.location}
                       onChange={handleInputChange}
                       autoComplete="off"/>
            </FormGroup>
            <FormGroup>
                <CustomInput type="switch"
                             name="debts"
                             id="debts"
                             label="Schulden"
                             checked={currentFormState.debts}
                             onChange={handleSwitchChange('debts', currentFormState.debts)}
                             autoComplete="off"/>
            </FormGroup>
            <FormGroup>
                <Button color="primary">Hinzufügen</Button>
            </FormGroup>
        </form>
    )
}

export default AddClientForm
