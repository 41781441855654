import React, {useState} from 'react'
import {Button, CustomInput, FormGroup, Input, Label} from 'reactstrap';

const AddFriendForm = props => {
    const initialFormState = {
        "id": "",
        "name": "",
        "tel": "",
        "mail": "",
        "lead": 0
    }
    const [currentFormState, setCurrentFormState] = useState(initialFormState)

    const handleInputChange = event => {
        const {name, value} = event.target

        setCurrentFormState({...currentFormState, [name]: value})
    }

    const handleSwitchChange = (name, value) => () => {
        setCurrentFormState({...currentFormState, [name]: !value})
    }

    return (
        <form
            onSubmit={event => {
                event.preventDefault()
                if (!currentFormState.name) return

                props.addRow(currentFormState)
                setCurrentFormState(initialFormState)
            }}
        >
            <FormGroup>
                <Label htmlFor="name">Name</Label>
                <Input type="text"
                       name="name"
                       id="name"
                       value={currentFormState.name}
                       onChange={handleInputChange}
                       autoComplete="off"
                       required/>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="tel">Telefon</Label>
                <Input type="tel"
                       name="tel"
                       id="tel"
                       value={currentFormState.tel}
                       onChange={handleInputChange}
                       autoComplete="off"/>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="mail">E-Mail</Label>
                <Input type="mail"
                       name="mail"
                       id="mail"
                       value={currentFormState.mail}
                       onChange={handleInputChange}
                       autoComplete="off"/>
            </FormGroup>
            <FormGroup>
                <CustomInput type="switch"
                             name="lead"
                             id="lead"
                             label="Leader"
                             checked={currentFormState.lead}
                             onChange={handleSwitchChange('lead', currentFormState.lead)}
                             autoComplete="off"/>
            </FormGroup>
            <FormGroup>
                <Button color="primary">Hinzufügen</Button>
            </FormGroup>
        </form>
    )
}

export default AddFriendForm
