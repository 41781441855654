import React, {useEffect, useState} from 'react'
import {Button, ButtonGroup, CustomInput, FormGroup, Input, Label} from 'reactstrap';

import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/dark.css";
import {German} from "flatpickr/dist/l10n/de.js"

import moment from 'moment';

moment().locale("de");

const EditOrderForm = props => {
    const [currentFormState, setCurrentFormState] = useState(props.currentRow)

    useEffect(
        () => {
            setCurrentFormState(props.currentRow)
        },
        [props]
    )

    const handleInputChange = event => {
        const {name, value} = event.target

        setCurrentFormState({...currentFormState, [name]: value})
    }

    const handleCustomInputChange = order => {
        const {name, value} = order.target

        setCurrentFormState({...currentFormState, [name]: Number(value)})
    }

    const handleSwitchChange = (name, value) => () => {
        setCurrentFormState({...currentFormState, [name]: !value})
    }

    const handleDateChange = (name, value) => {
        setCurrentFormState({...currentFormState, [name]: moment(value).format("YYYY-MM-DD")})
    }

    return (
        <form
            onSubmit={event => {
                event.preventDefault()
                if (!currentFormState.fkClient || !currentFormState.fkItem || !currentFormState.fkFriend) {
                    return;
                }

                props.updateRow(currentFormState.id, currentFormState)
            }}
        >
            <FormGroup>
                <Label htmlFor="fkClient">Kunde</Label>
                <CustomInput type="select"
                             name="fkClient"
                             id="fkClient"
                             onChange={handleCustomInputChange}
                             value={currentFormState.fkClient}
                             required>
                    {props.clients.map(option =>
                        <option key={option.id} value={option.id}>{option.name}</option>
                    )}
                </CustomInput>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="fkItem">Bühne</Label>
                <CustomInput type="select"
                             name="fkItem"
                             id="fkItem"
                             onChange={handleCustomInputChange}
                             value={currentFormState.fkItem}
                             required>
                    {props.items.map(option =>
                        <option key={option.id} value={option.id}>{option.name}</option>
                    )}
                </CustomInput>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="fkFriend">Zuständig</Label>
                <CustomInput type="select"
                             name="fkFriend"
                             id="fkFriend"
                             onChange={handleCustomInputChange}
                             value={currentFormState.fkFriend}
                             required>
                    {props.friends.filter(friend => friend.lead === 1).map(option =>
                        <option key={option.id} value={option.id}>{option.name}</option>
                    )}
                </CustomInput>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="rangeDate">Datum</Label>
                <Flatpickr id="rangeDate"
                           options={{
                               altFormat: "j F Y",
                               altInput: true,
                               dateFormat: "Y-m-d",
                               defaultDate: [currentFormState.startDate, currentFormState.endDate],
                               disable: props.orders.filter(order => order.id !== currentFormState.id && order.fkItem === currentFormState.fkItem).map(order => {
                                   return {
                                       from: order.startDate,
                                       to: order.endDate
                                   }
                               }),
                               inline: true,
                               locale: German,
                               mode: "range",
                               showMonths: 3,
                           }}
                           onChange={range => {
                               console.info('range.length', range.length, range[0], range[1]);
                               if (range.length === 2) {
                                handleDateChange(['startDate', range[0], ]);
                                handleDateChange();
                               }
                           }}/>
            </FormGroup>
            <FormGroup>
                <CustomInput type="switch"
                             name="fix"
                             id="fix"
                             label="Fix"
                             checked={currentFormState.fix}
                             onChange={handleSwitchChange('fix', currentFormState.fix)}
                             autoComplete="off"/>
            </FormGroup>
            <FormGroup>
                <CustomInput type="switch"
                             name="molton"
                             id="molton"
                             label="Molton"
                             checked={currentFormState.molton}
                             onChange={handleSwitchChange('molton', currentFormState.molton)}
                             autoComplete="off"/>
            </FormGroup>
            <FormGroup>
                <CustomInput type="switch"
                             name="anbau"
                             id="anbau"
                             label="Anbau"
                             checked={currentFormState.anbau}
                             onChange={handleSwitchChange('anbau', currentFormState.anbau)}
                             autoComplete="off"/>
            </FormGroup>
            <FormGroup>
                <Input type="textarea"
                       name="text"
                       id="text"
                       value={currentFormState.text}
                       onChange={handleInputChange}
                       autoComplete="off"/>
            </FormGroup>
            <ButtonGroup>
                <Button color="primary">Speichern</Button>
                <Button color="secondary" onClick={() => props.setEditing(false)}>Abbrechen</Button>
            </ButtonGroup>
        </form>
    )
}

export default EditOrderForm
