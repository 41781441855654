import React, {useState} from 'react';
import {Link} from 'react-router-dom'
import {Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem} from 'reactstrap';

const Navigation = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <Navbar color="light" light expand="md">
            <NavbarBrand href="/calendar">Bühnen Kalender</NavbarBrand>
            <NavbarToggler onClick={toggle}/>
            <Collapse isOpen={isOpen} navbar>
                <Nav className="mr-auto" navbar>
                    <NavItem>
                        <Link className="nav-link" to="/orders">Aufträge</Link>
                    </NavItem>
                    <NavItem>
                        <Link className="nav-link" to="/clients">Kunden</Link>
                    </NavItem>
                    <NavItem>
                        <Link className="nav-link" to="/contacts">Kontakte</Link>
                    </NavItem>
                    <NavItem>
                        <Link className="nav-link" to="/friends">Ali</Link>
                    </NavItem>
                    <NavItem>
                        <Link className="nav-link" to="/history">History</Link>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>
    );
}

export default Navigation
