import React from 'react'
import {Button} from 'reactstrap';
import {Alink, ColorBubble} from '../helpers/tags'

const UserTable = props => (
    <table>
        <thead>
        <tr>
            <th colSpan={2}>Kunde</th>
            <th>Name</th>
            <th>Telefon</th>
            <th>E-Mail</th>
            <th colSpan={2}></th>
        </tr>
        </thead>
        <tbody>
        {props.contacts.length > 0 ? (
            props.contacts.map(contact => (
                <tr key={contact.id}>
                    <td><ColorBubble color={props.clients.find(client => contact.fkClient === client.id).color}/></td>
                    <td>{props.clients.find(client => contact.fkClient === client.id).name}</td>
                    <td>{contact.name}</td>
                    <td><Alink prot="tel" uri={contact.tel}/></td>
                    <td><Alink prot="mail" uri={contact.mail}/></td>
                    <td><Button color="primary" onClick={() => props.editRow(contact)}>Bearbeiten</Button></td>
                </tr>
            ))
        ) : (
            <tr>
                <td colSpan={6}>Keine Kontakte</td>
            </tr>
        )}
        </tbody>
    </table>
)

export default UserTable
