import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Navigation from './Navigation'
import Calendar from './Calendar'
import Orders from './Orders'
import Clients from './Clients'
import Contacts from './Contacts'
import Friends from './Friends'
import History from './History'
import {Container} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
    return (
        <Router>
            <Navigation/>
            <Container fluid>
                <Switch>
                    <Route path="/calendar" component={Calendar}/>
                    <Route path="/clients" component={Clients}/>
                    <Route path="/orders" component={Orders}/>
                    <Route path="/contacts" component={Contacts}/>
                    <Route path="/friends" component={Friends}/>
                    <Route path="/history" component={History}/>
                    <Route path="/" component={Calendar}/>
                </Switch>
            </Container>
        </Router>
    )
}

export default App
