const TextColor = (color = '#FF00FF') => {
    var hex = color.replace('#', '');
    var bigint = parseInt(hex, 16);
    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;
    var o = Math.round((
        (parseInt(r) * 299) +
        (parseInt(g) * 587) +
        (parseInt(b) * 114)
    ) / 1000);
    return (o > 125) ? 'black' : 'white';
}

const RandomColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`

export {TextColor, RandomColor}
