import React, {useEffect, useState} from 'react'
import {LoadingFailedToast, LoadingToast} from './helpers/tags'
import {RandomColor} from './helpers/arrows'

import AddClientForm from './forms/AddClientForm'
import EditClientForm from './forms/EditClientForm'
import ClientTable from './tables/ClientTable'

const Clients = () => {
    const initialFormState = {
        "id": "",
        "name": "",
        "color": RandomColor,
        "location": "",
        "debts": 0
    }

    // Setting state
    const [isLoading, setLoading] = useState(true)
    const [hasError, setErrors] = useState(false)
    const [clients, setClients] = useState(null)
    const [currentRow, setCurrentRow] = useState(initialFormState)
    const [editing, setEditing] = useState(false)

    async function fetchData() {
        const resClients = await fetch("/api/clients.json");
        resClients.json()
            .then(resClients => setClients(resClients.sort((a, b) => a.name > b.name)))
            .catch(err => setErrors(err));
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setLoading(!clients)
    }, [clients]);

    if (hasError) {
        return <LoadingFailedToast/>
    }

    if (isLoading) {
        return <LoadingToast/>
    }

    // CRUD operations
    const addRow = async insertedRow => {
        setEditing(false)

        insertedRow = await fetch("/api/clients.json", {
            method: 'POST',
            body: JSON.stringify(insertedRow),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => response.json());

        setClients([...clients, insertedRow])
    }

    const updateRow = async (id, updatedRow) => {
        setEditing(false)

        updatedRow = await fetch(`/api/clients/${id}.json`, {
            method: 'PUT',
            body: JSON.stringify(updatedRow),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => response.json());

        setClients(clients.map(client => (client.id === id ? updatedRow : client)))
    }

    const editRow = async client => {
        await fetchData();

        setEditing(true)

        setCurrentRow(client)
    }

    return (
        <>
            <h1>Bühnen Kunden</h1>
            <div className="flex-row">
                <div className="flex-large">
                    {editing ? (
                        <>
                            <h2>Bearbeiten</h2>
                            <EditClientForm
                                editing={editing}
                                setEditing={setEditing}
                                currentRow={currentRow}
                                updateRow={updateRow}
                            />
                        </>
                    ) : (
                        <>
                            <h2>Erstellen</h2>
                            <AddClientForm addRow={addRow}/>
                        </>
                    )}
                </div>
                <div className="flex-large">
                    <h2>Liste</h2>
                    <ClientTable clients={clients}
                                 editRow={editRow}
                    />
                </div>
            </div>
        </>
    )
}

export default Clients
