import React, {useEffect, useState} from 'react'
import {LoadingFailedToast, LoadingToast} from './helpers/tags'

import AddOrderForm from './forms/AddOrderForm'
import EditOrderForm from './forms/EditOrderForm'
import OrderTable from './tables/OrderTable'

import moment from 'moment'
import 'moment/locale/de'

const Orders = () => {
    const initialFormState = {
        "id": "",
        "fkClient": -1,
        "fkItem": -1,
        "fkFriend": -1,
        "startDate": "",
        "endDate": "",
        "fix": "",
        "molton": "",
        "anabu": ""
    }

    // Setting state
    const [isLoading, setLoading] = useState(true)
    const [hasError, setErrors] = useState(false)
    const [orders, setOrders] = useState(null)
    const [clients, setClients] = useState(null)
    const [contacts, setContacts] = useState(null)
    const [friends, setFriends] = useState(null)
    const [items, setItems] = useState(null)
    const [currentRow, setCurrentRow] = useState(initialFormState)
    const [editing, setEditing] = useState(false)

    async function fetchData() {
        const resOrders = await fetch("/api/calendar.json");
        resOrders.json()
            .then(resOrders => {
                let filteredOrders = resOrders.filter(order => {
                    return moment().isBefore(order.endDate);
                }).sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
                setOrders(filteredOrders);
            })
            .catch(err => setErrors(err));
    }

    async function fetchAllData() {
        await fetchData();

        const resClients = await fetch("/api/clients.json");
        resClients.json()
            .then(resClients => setClients(resClients.sort((a, b) => a.name > b.name)))
            .catch(err => setErrors(err));

        const resContacts = await fetch("/api/contacts.json");
        resContacts.json()
            .then(resContacts => setContacts(resContacts.sort((a, b) => a.name > b.name)))
            .catch(err => setErrors(err));

        const resFriends = await fetch("/api/friends.json");
        resFriends.json()
            .then(resFriends => setFriends(resFriends.sort((a, b) => a.name > b.name)))
            .catch(err => setErrors(err));

        const resItems = await fetch("/api/items.json");
        resItems.json()
            .then(resItems => setItems(resItems.sort((a, b) => a.name > b.name)))
            .catch(err => setErrors(err));
    }

    useEffect(() => {
        fetchAllData();
    }, []);

    useEffect(() => {
        setLoading(!orders || !clients || !contacts || !friends || !items)
    }, [orders, clients, contacts, friends, items]);

    if (hasError) {
        return <LoadingFailedToast/>
    }

    if (isLoading) {
        return <LoadingToast/>
    }

    // CRUD operations
    const addRow = async insertedRow => {
        setEditing(false)

        insertedRow = await fetch("/api/calendar.json", {
            method: 'POST',
            body: JSON.stringify(insertedRow),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => response.json());

        setOrders([...orders, insertedRow])
    }

    const updateRow = async (id, updatedRow) => {
        setEditing(false)

        updatedRow = await fetch(`/api/calendar/${id}.json`, {
            method: 'PUT',
            body: JSON.stringify(updatedRow),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => response.json());

        setOrders(orders.map(order => (order.id === id ? updatedRow : order)))
    }

    const editRow = async order => {
        await fetchData();

        setEditing(true)

        setCurrentRow(order)
    }

    return (
        <>
            <h1>Bühnen Bestellungen</h1>
            <div className="flex-row">
                <div className="flex-large">
                    {editing ? (
                        <>
                            <h2>Bearbeiten</h2>
                            <EditOrderForm
                                editing={editing}
                                setEditing={setEditing}
                                currentRow={currentRow}
                                updateRow={updateRow}
                                clients={clients}
                                contacts={contacts}
                                friends={friends}
                                items={items}
                                orders={orders}
                            />
                        </>
                    ) : (
                        <>
                            <h2>Erstellen</h2>
                            <AddOrderForm addRow={addRow}
                                          clients={clients}
                                          contacts={contacts}
                                          friends={friends}
                                          items={items}
                                          orders={orders}
                            />
                        </>
                    )}
                </div>
                <div className="flex-large">
                    <h2>Liste</h2>
                    <OrderTable orders={orders}
                                clients={clients}
                                contacts={contacts}
                                friends={friends}
                                items={items}
                                editRow={editRow}
                    />
                </div>
            </div>
        </>
    )
}

export default Orders
