import React, {useEffect, useState} from 'react'
import {Col, Row, Toast, ToastBody, ToastHeader} from 'reactstrap';
import {Alink, LoadingFailedToast, LoadingToast} from './helpers/tags'
import {TextColor} from './helpers/arrows'

import moment from 'moment'
import 'moment/locale/de'

import Timeline from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'

function getBackgroundColor(color = '#FF00FF') {
    return color
}

const OrderToast = props => (
    props.order ? (
        <Toast style={{margin: '1rem auto'}}>
            <ToastHeader style={{
                color: TextColor(props.client.color),
                backgroundColor: getBackgroundColor(props.client.color)
            }}>
                {props.client.name}
            </ToastHeader>
            <ToastBody>
                {props.contacts.length > 0 ? (
                    props.contacts.map(contact => (
                        <div key={contact.id}>
                            <Row>
                                <Col xs="3">Name</Col>
                                <Col>{contact.name}</Col>
                            </Row>
                            <Row>
                                <Col xs="3">Telefon</Col>
                                <Col><Alink prot="tel" uri={contact.tel}/></Col>
                            </Row>
                            <Row>
                                <Col xs="3">E-Mail</Col>
                                <Col><Alink prot="mail" uri={contact.mail}/></Col>
                            </Row>
                        </div>
                    ))
                ) : (
                    <span>Keine Kontakte</span>
                )}
            </ToastBody>
        </Toast>
    ) : (
        <></>
    )
)

const Calendar = () => {
    const [isLoading, setLoading] = useState(true)
    const [hasError, setErrors] = useState(false)
    const [orders, setOrders] = useState(null)
    const [clients, setClients] = useState(null)
    const [contacts, setContacts] = useState(null)
    const [items, setItems] = useState(null)
    const [friends, setFriends] = useState(null)
    const [currentOrder, setCurrentOrder] = useState()
    const [currentClient, setCurrentClient] = useState()
    const [currentContacts, setCurrentContacts] = useState(null)
    const [currentFriends, setCurrentFriends] = useState(null)

    async function fetchData() {
        const resOrders = await fetch("/api/calendar.json");
        resOrders.json()
            .then(resOrders => setOrders(resOrders))
            .catch(err => setErrors(err));

        const resClients = await fetch("/api/clients.json");
        resClients.json()
            .then(resClients => setClients(resClients))
            .catch(err => setErrors(err));

        const resContacts = await fetch("/api/contacts.json");
        resContacts.json()
            .then(resContacts => setContacts(resContacts))
            .catch(err => setErrors(err));

        const resItems = await fetch("/api/items.json");
        resItems.json()
            .then(resItems => setItems(resItems))
            .catch(err => setErrors(err));

        const resFriends = await fetch("/api/friends.json");
        resFriends.json()
            .then(resFriends => setFriends(resFriends))
            .catch(err => setErrors(err));
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setLoading(!orders || !clients || !contacts || !friends || !items)
    }, [orders, clients, contacts, friends, items]);

    if (hasError) {
        return <LoadingFailedToast/>
    }

    if (isLoading) {
        return <LoadingToast/>
    }

    let tlData = {
        groups: items.map(item => {
            return {
                id: item.id,
                title: item.name
            }
        }),
        items: orders.map(order => {
            let client = clients.find(client => client.id === order.fkClient);

            return {
                id: order.id,
                group: order.fkItem,
                title: client.name,
                start_time: moment(order.startDate),
                end_time: moment(order.endDate),
                itemProps: {
                    style: {
                        background: getBackgroundColor(client.color),
                        color: TextColor(client.color),
                        opacity: order.fix ? 'unset' : '50%',
                        filter: !order.covid ? 'unset' : 'grayscale(50%)'
                    }
                }
            }
        })
    }

    let tlDefaultOptions = {
        canMove: false,
        canResize: false,
        useResizeHandle: false,
        canChangeGroup: false,
        sidebarWidth: 200
    }

    let tlZoomOptions = {
        defaultTimeStart: moment().subtract(10, 'day'),
        defaultTimeEnd: moment().add(60, 'day'),
        minZoom: moment.duration(7, 'days').asMilliseconds(),
        maxZoom: moment.duration(2, 'months').asMilliseconds()
    }

    const handleItemSelect = (itemId, _) => {
        const order = orders.find(order => order.id === itemId)
        const client = clients.find(client => client.id === order.fkClient)
        const contact = contacts.filter(contact => contact.fkClient === client.id)
        const friend = friends.filter(friend => friend.fkFriend === friend.id)

        setCurrentOrder(order)
        setCurrentClient(client)
        setCurrentContacts(contact)
        setCurrentFriends(friend)
    }

    return (
        <>
            <Timeline
                {...tlData}
                {...tlDefaultOptions}
                {...tlZoomOptions}
                onItemSelect={handleItemSelect}
            />
            <OrderToast order={currentOrder}
                        client={currentClient}
                        contacts={currentContacts}
                        friends={currentFriends}/>
        </>
    )
}

export default Calendar
