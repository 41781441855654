import React, {useEffect, useState} from 'react'
import {LoadingFailedToast, LoadingToast} from './helpers/tags'

import AddContactForm from './forms/AddContactForm'
import EditContactForm from './forms/EditContactForm'
import ContactTable from './tables/ContactTable'

const Contacts = () => {
    const initialFormState = {
        "id": "",
        "fkClient": -1,
        "name": "",
        "tel": "",
        "mail": ""
    }

    // Setting state
    const [isLoading, setLoading] = useState(true)
    const [hasError, setErrors] = useState(false)
    const [contacts, setContacts] = useState(null)
    const [clients, setClients] = useState(null)
    const [currentRow, setCurrentRow] = useState(initialFormState)
    const [editing, setEditing] = useState(false)

    async function fetchData() {
        const resContacts = await fetch("/api/contacts.json");
        resContacts.json()
            .then(resContacts => setContacts(resContacts.sort((a, b) => a.name > b.name)))
            .catch(err => setErrors(err));
    }

    async function fetchAllData() {
        await fetchData();

        const resClients = await fetch("/api/clients.json");
        resClients.json()
            .then(resClients => setClients(resClients.sort((a, b) => a.name > b.name)))
            .catch(err => setErrors(err));
    }

    useEffect(() => {
        fetchAllData();
    }, []);

    useEffect(() => {
        setLoading(!contacts || !clients)
    }, [contacts, clients]);

    if (hasError) {
        return <LoadingFailedToast/>
    }

    if (isLoading) {
        return <LoadingToast/>
    }

    // CRUD operations
    const addRow = async insertedRow => {
        insertedRow = await fetch("/api/contacts.json", {
            method: 'POST',
            body: JSON.stringify(insertedRow),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => response.json());

        setContacts([...contacts, insertedRow])
    }

    const updateRow = async (id, updatedRow) => {
        setEditing(false)

        updatedRow = await fetch(`/api/contacts/${id}.json`, {
            method: 'PUT',
            body: JSON.stringify(updatedRow),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => response.json());

        setContacts(contacts.map(contact => (contact.id === id ? updatedRow : contact)))
    }

    const editRow = async contact => {
        await fetchData();

        setEditing(true)

        setCurrentRow(contact)
    }

    return (
        <>
            <h1>Kunden Kontakte</h1>
            <div className="flex-row">
                <div className="flex-large">
                    {editing ? (
                        <>
                            <h2>Bearbeiten</h2>
                            <EditContactForm
                                editing={editing}
                                setEditing={setEditing}
                                currentRow={currentRow}
                                updateRow={updateRow}
                                clients={clients}
                            />
                        </>
                    ) : (
                        <>
                            <h2>Erstellen</h2>
                            <AddContactForm addRow={addRow}
                                            clients={clients}/>
                        </>
                    )}
                </div>
                <div className="flex-large">
                    <h2>Liste</h2>
                    <ContactTable contacts={contacts}
                                  clients={clients}
                                  editRow={editRow}
                    />
                </div>
            </div>
        </>
    )
}

export default Contacts
