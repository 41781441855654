import React from 'react'
import {Spinner, Toast, ToastBody, ToastHeader} from 'reactstrap';

import Moment from 'react-moment';
import moment from 'moment';
moment().locale("de");

const Alink = props => {
    return <a href={props.prot + ":" + props.uri}>{props.uri}</a>
}

const ColorBubble = props => {
    return <div style={{backgroundColor: props.color, borderRadius: '50%', height: '15px', width: '15px'}}></div>
}

const LoadingToast = props => {
    const title = props.title ? props.title : "Laden";
    const message = props.message ? props.message : "Daten werden geladen...";
    return <Toast style={{margin: '1rem auto'}}>
        <ToastHeader icon={<Spinner size="sm"/>}>
            {title}
        </ToastHeader>
        <ToastBody>
            {message}
        </ToastBody>
    </Toast>
}

const LoadingFailedToast = props => {
    const title = props.title ? props.title : "Fehler";
    const message = props.message ? props.message : "Daten konnten nicht geladen werden...";
    return <Toast style={{margin: '1rem auto'}}>
        <ToastHeader icon="danger">
            {title}
        </ToastHeader>
        <ToastBody>
            {message}
        </ToastBody>
    </Toast>
}

const DateRange = props => {
    if (moment(props.startDate).isSame(props.endDate)) {
        const singleDateFormat = props.showYear ? "D MMMM YYYY" : "D MMMM";
        return <Moment locale="de" format={singleDateFormat}>{props.startDate}</Moment>
    }

    const startDateFormat = moment(props.startDate).isSame(props.endDate, 'month') ? "D" : "D MMMM";
    const endDateFormat = props.showYear ? "D MMMM YYYY" : "D MMMM";
    return <>
        <Moment locale="de" format={startDateFormat}>{props.startDate}</Moment>
        <span> - </span>
        <Moment locale="de" format={endDateFormat}>{props.endDate}</Moment>
    </>
}

export {Alink, ColorBubble, LoadingToast, LoadingFailedToast, DateRange}
