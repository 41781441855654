import React, {useEffect, useState} from 'react'
import {LoadingFailedToast, LoadingToast} from './helpers/tags'

import HistoryTable from './tables/HistoryTable'

import moment from 'moment'
import 'moment/locale/de'

const History = () => {
    // Setting state
    const [isLoading, setLoading] = useState(true)
    const [hasError, setErrors] = useState(false)
    const [orders, setOrders] = useState(null)
    const [clients, setClients] = useState(null)
    const [contacts, setContacts] = useState(null)
    const [friends, setFriends] = useState(null)
    const [items, setItems] = useState(null)

    async function fetchData() {
        const resOrders = await fetch("/api/calendar.json");
        resOrders.json()
            .then(resOrders => {
                let filteredOrders = resOrders.filter(order => {
                    return moment().isAfter(order.endDate);
                }).sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
                setOrders(filteredOrders);
            })
            .catch(err => setErrors(err));
    }

    async function fetchAllData() {
        await fetchData();

        const resClients = await fetch("/api/clients.json");
        resClients.json()
            .then(resClients => setClients(resClients.sort((a, b) => a.name > b.name)))
            .catch(err => setErrors(err));

        const resContacts = await fetch("/api/contacts.json");
        resContacts.json()
            .then(resContacts => setContacts(resContacts.sort((a, b) => a.name > b.name)))
            .catch(err => setErrors(err));

        const resFriends = await fetch("/api/friends.json");
        resFriends.json()
            .then(resFriends => setFriends(resFriends.sort((a, b) => a.name > b.name)))
            .catch(err => setErrors(err));

        const resItems = await fetch("/api/items.json");
        resItems.json()
            .then(resItems => setItems(resItems.sort((a, b) => a.name > b.name)))
            .catch(err => setErrors(err));
    }

    useEffect(() => {
        fetchAllData();
    }, []);

    useEffect(() => {
        setLoading(!orders || !clients || !contacts || !friends || !items)
    }, [orders, clients, contacts, friends, items]);

    if (hasError) {
        return <LoadingFailedToast/>
    }

    if (isLoading) {
        return <LoadingToast/>
    }

    return (
        <>
            <h1>Bühnen Bestellungen</h1>
            <div className="flex-row">
                <div className="flex-large">
                    <h2>Liste</h2>
                    <HistoryTable orders={orders}
                                  clients={clients}
                                  contacts={contacts}
                                  friends={friends}
                                  items={items}
                    />
                </div>
            </div>
        </>
    )
}

export default History
